import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

const OVERLAY_CLASS = 'loading-overlay';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent implements OnChanges {
  @Input() isLoading = false;

  overlayElement!: HTMLDivElement;
  spinnerElement!: MatProgressSpinner;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      const isLoadingValue = changes.isLoading.currentValue;

      if (isLoadingValue) {
        this.addLoadingIndicator();
      } else {
        this.removeLoadingIndicator();
      }
    }
  }

  protected addLoadingIndicator(): void {
    const overlayElement = document.querySelector('.overlay');
    if (overlayElement) {
      overlayElement.classList.add('show');
    }
  }

  protected removeLoadingIndicator(): void {
    const overlayElement = document.querySelector('.overlay');
    if (overlayElement) {
      overlayElement.classList.remove('show');
    }
  }
}
