import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {MatSidenav} from "@angular/material/sidenav";
import {LayoutService} from "@layout/service/layout.service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {

  $destroy: Subject<void> = new Subject<void>();
  isMobile: boolean = false;

  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;

  constructor(private router: Router,
              private layoutService: LayoutService) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url) {
        console.log(event.url);
      }
    });

    this.layoutService.sidenavDisplaySubject
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: (displaySidenav) => this.sidenav.opened = displaySidenav
      });

    this.layoutService.isMobileSubject
      .pipe(takeUntil(this.$destroy))
      .subscribe((isMobile) => this.isMobile = isMobile);
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

}
